import configure from "@/Plugins/configure.js";
import { createInertiaApp } from "@inertiajs/vue3";
import axios from "axios";
import { createApp, h } from "vue";
import "../css/app.css";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

createInertiaApp({
    progress: {
        color: "#0E5425",
    },
    title: (title) => `LemonSwan - ${title} `,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) });
        configure(vueApp.use(plugin)).mount(el);
    },
});
